import * as React from 'react'
import * as c3 from 'c3'
import 'c3/c3.css'

const chartStyle = {
  background: '#fff',
  width: '90%',
  margin: '1em auto',
}

const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '75%',
}

/**
 * This function takes an array of plot config and generates data to be supplied to C3 charts.
 * @param { plotConfigProps[] } plotConfig
 * interface plotConfigProps {
 *   length: number // length of the plot
 *   maxValue: number // max value of the plot
 * }
 */
const getData = (plotConfig) => {
  if (Array.isArray(plotConfig)) {
    return plotConfig.reduce((sum, { length, maxValue }, index) => {
      const data = [`data${index}`]
      for (let i = 0; i < length; i++) {
        data.push(Math.floor(Math.random() * maxValue))
      }
      sum.push(data)
      return sum
    }, [])
  }

  return []
}

const Chart = () => {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    const interval = setData(
      getData([
        { length: 20, maxValue: 400 },
        { length: 20, maxValue: 200 },
        { length: 20, maxValue: 100 },
        { length: 20, maxValue: 20 },
        { length: 20, maxValue: 300 },
      ])
    )
    return () => interval
  }, [])

  React.useEffect(() => {
    const interval = setData()
  })

  React.useEffect(() => {
    c3.generate({
      bindto: '#chart',
      data: {
        columns: data,
        type: 'line',
      },
    })
  }, [data])

  return (
    <div style={container}>
      <div>
        <p>Hello World Gatsby!</p>
      </div>
      <div id="chart" style={chartStyle}></div>
    </div>
  )
}

export default Chart
